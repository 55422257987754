import { CategoryLink } from '@/sanity/fetchCategories';
import { URL_PATH_CATEGORY } from '@/shared/constants';
import HIHLink from './Link';

interface TagProps {
  label: string;
  href?: string;
  size?: 'sm' | 'md';
  variant?: 'light' | 'dark';
  className?: string;
  hasHoverEffect?: boolean;
}

export const CategoryTag = ({
  category,
  size = 'sm',
}: {
  category: CategoryLink;
  size?: 'sm' | 'md';
}) => {
  return (
    <Tag
      label={category.name}
      href={`/${URL_PATH_CATEGORY}/${category.slug.current}`}
      size={size}
    />
  );
};

export default function Tag({
  label,
  href,
  size = 'md',
  variant = 'dark',
  className,
  hasHoverEffect = true,
}: TagProps) {
  // const py = size === 'sm' ? 'py-sm' : 'py-lg';

  const height = size === 'sm' ? 'h-[32px]' : 'h-[44px]';
  const px = size === 'sm' ? 'px-xl' : 'px-2xl';

  const bgColor =
    variant === 'light'
      ? `bg-sys/inverse/inverse-on-surface ${hasHoverEffect && 'hover:bg-sys/inverse/inverse-on-surface/80'}`
      : `bg-sys/primary/primary ${hasHoverEffect && 'hover:bg-[#2c2c2c]'}`;
  const textColor =
    variant === 'light'
      ? 'text-sys/inverse/inverse-surface'
      : 'text-sys/primary/on-primary';
  const radius = size === 'sm' ? 'rounded-xs' : 'rounded-sm';

  const style = `${bgColor} ${textColor} ${radius} ${className} flex flex-col justify-center ${height} ${px} truncate`;
  const content = <span className="font-body text-label-lg">{label}</span>;

  if (href !== undefined) {
    return (
      <a href={href}>
        <div className={style}>{content}</div>
      </a>
    );
  } else {
    return <div className={style}>{content}</div>;
  }
}
