import { ArticleCard } from '@/sanity/fetchArticles';
import HIHImage from './Image';
import Tag from './Tag';
import HIHLink from './Link';
import { URL_PATH_ARTICLE, URL_PATH_CATEGORY } from '@/shared/constants';

interface SingleArticleCardProps {
  article: ArticleCard;
  variant?: 'primary' | 'secondary';
}

export default function SingleArticleMobileFullViewCard({
  article,
  variant = 'primary',
}: SingleArticleCardProps) {
  const categories = article.categories?.slice(0, 3);
  return (
    <div
      className={`flex flex-col gap-0 overflow-hidden ${variant == 'primary' && 'rounded-2xl shadow-1'} group`}
    >
      <HIHLink key={article._id} href={`/guide/${article.slug?.current}`}>
        <div
          className={`${variant == 'primary' ? 'rounded-0' : 'rounded-2xl'} overflow-hidden aspect-4/3 bg-sys/primary/on-primary `}
        >
          <HIHImage
            image={article.coverImage}
            width={470}
            height={352}
            className="w-full h-full object-cover group-hover:scale-105 transition scale-100 duration-500"
          />
        </div>
      </HIHLink>
      <div
        className={`flex flex-col ${variant == 'primary' ? 'p-3xl gap-xl bg-sys/surface/surface-container-lowest' : 'pt-2xl gap-2xl'} lg:h-[310px]`}
      >
        <div className="gap-xs flex flex-wrap h-[32px] overflow-hidden">
          {categories?.map((category) => (
            <Tag
              key={category._id}
              label={category.name}
              size="sm"
              hasHoverEffect={false}
              href={`/${URL_PATH_CATEGORY}/${category.slug?.current}`}
            />
          ))}
        </div>
        <HIHLink key={article._id} href={`/${URL_PATH_ARTICLE}/${article.slug?.current}`}>
          <div className="flex flex-col gap-xl">
            <div className="font-display text-headline-lg line-clamp-3">
              {article.title}
            </div>
            <div className="!line-clamp-3 font-body text-body-lg text-sys/surface/on-surface-variant">
              {article.shortDescription}
            </div>
          </div>
        </HIHLink>
      </div>
    </div>
  );
}
