import Image from 'next/image';
import { EventCard } from '@/sanity/fetchEvents';
import { EventDate } from '@/shared/types/eventData';
import { format } from 'date-fns';
import Chip from '../base/Chip';
import { generateGoogleMapsLink } from '@/utils/api-helpers';

export const IconWithText = ({ icon, text }: { icon: string; text?: string }) => {
  if (!text) return null;
  return (
    <div className="flex gap-xs sm:gap-md items-center">
      <Image
        src={icon}
        width={18}
        height={18}
        alt="Icon"
        className=" md:block w-[14px] md:w-[18px]"
      />
      <div className="text-label-lg truncate">{text}</div>
    </div>
  );
};

// Main component for event details icons
const EventFacts = ({
  event,
  currentDate = new Date(),
  className,
}: {
  event: EventCard;
  currentDate?: Date;
  className?: string;
}) => {
  const firstValidEventDate = getFirstValidEventDate(event, currentDate); // Assume this function is defined elsewhere
  const googleMapsLinks = generateGoogleMapsLink(event.location);

  return (
    <div className={className}>
      <div className="flex flex-wrap">
        {firstValidEventDate && (
          <Chip label={firstValidEventDate} icon="/icons/icon-calendar.svg" />
        )}
        {event?.startTime && (
          <Chip
            label={`${event.startTime}  ${event.endTime ? ` - ${event.endTime}` : ''} Uhr`}
            icon="/icons/icon-clock.svg"
          />
        )}
        <div className="w-full lg:hidden" />
        {event?.location?.name && (
          <Chip
            label={event.location?.name}
            icon="/icons/icon-map.svg"
            href={googleMapsLinks}
          />
        )}
        {(event?.ticketLink || event?.price) && (
          <Chip
            label={
              event.price
                ? event.ticketLink
                  ? `Ticket (${event.price})`
                  : `${event.price}`
                : 'Ticket'
            }
            icon="/icons/icon-ticket.svg"
            href={event.ticketLink}
          />
        )}
      </div>
    </div>
  );
};

export default EventFacts;

// Defines a function to get the first valid event date or a formatted date message
export function getFirstValidEventDate(event: EventCard, date: Date): string | undefined {
  const todayDate = format(date, 'yyyy-MM-dd');

  // Finds the first future or ongoing event date
  const firstValidDate = event?.eventDates?.find(
    (date) => date.startDate >= todayDate || (date.endDate && date.endDate >= todayDate),
  );

  if (!firstValidDate) {
    // Handles case where no valid future or ongoing date is found
    return formatEventDateMessage(event?.eventDates[0]);
  }

  // Special case: If the event starts today and is a one-day event don't display a date
  if (firstValidDate.startDate === todayDate && !firstValidDate.endDate) {
    return undefined;
  }

  // Returns formatted date message for the valid date
  return formatEventDateMessage(firstValidDate);
}

// Helper function to format a date range or single date into a string message
function formatEventDateMessage(date: EventDate): string | undefined {
  const formattedStartDate = format(new Date(date.startDate), 'dd.MM');

  if (date.endDate) {
    const formattedEndDate = format(new Date(date.endDate), 'dd.MM');
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return formattedStartDate ?? undefined;
}
