import { IconWithText } from '../sections/EventFacts';
import HIHLink from './Link';

interface ChipProps {
  label: string;
  href?: string;
  icon?: string;
  className?: string;
}

export default function Chip({ label, href, icon, className }: ChipProps) {
  const content = icon ? (
    <IconWithText text={label} icon={icon} />
  ) : (
    <span className="text-label-text truncate">{label}</span>
  );
  const style = `flex bg-sys/surface/surface-container-high text-black px-md py-xs sm:py-[6px] sm:px-[12px] rounded-full ${className} mb-md mr-md lg:mg-lg lg:mr-lg text-label-lg`;

  if (href !== '' && href !== undefined && href !== null) {
    return (
      <div className={className}>
        <HIHLink href={href ?? ''} target="_blank" className={`${style} shadow-1 `}>
          {content}
        </HIHLink>
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div className={style}>{content}</div>
      </div>
    );
  }
}
